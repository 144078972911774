import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { useDispatch } from 'react-redux';

import { dateToString, SERVER_URL } from '../../../utils';
import { gnbSetting, currentTabSetting } from '../../../store';
import { useEffect } from 'react';

export interface NewsBoxProps {
  data: any;
}

const NewsBox = ({ data }: NewsBoxProps) => {

  const dispatch = useDispatch();

  // useEffect(() => {
  //   console.log('data',data);
  // }, []);

  return (
    <Link 
      className="newsbox"
      data-aos="fade-up"
      onClick={()=> {
        dispatch(currentTabSetting(0));
        dispatch(gnbSetting(0));
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }}
      to={`/news/detail/`+data.id}
      // state={{data : data}}
    >
      <div className={classNames(`sort_tag`, {news : data.category === "News"}, {notice : data.category === "Notice"},)}>
        <p>{data.category}</p>
      </div>
      {
        data.files === "" || data.files === undefined || data.files === null || data.files === "undefined"
        ? <div className={classNames(`image_none`)}></div>
        : <img className={classNames(`image`)} src={SERVER_URL+ "/" +data.files} alt="news thumnail"/>
      }
      <p className="title">{data.title}</p>
      <p className="date">{dateToString(data.regdate, 'yyyy.MM.dd')}</p>
    </Link>
  );
}

export default NewsBox;