import classNames from 'classnames';
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './newsDetail.scss';
import { dateToString } from '../../utils';

import { RootState, NEWSState } from '../../store';
import { useEffect, useState } from 'react';

import { SERVER_URL } from '../../utils';

import dummyNewsData from '../../assets/dummy.json';

export const getLastPath = (url: string) => {
  var splitUrl: string[] = [];
  splitUrl = url.split('/');
  return splitUrl[1];
}

const NewsDetail = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const [dataIndex, setDataIndex] = useState(0);
  const [newsDetail, setNewsDetail] = useState<any | undefined>();

//  const news:NEWSState = useSelector((state: RootState) => state.news);

//  const newsData:any[] = dummyNewsData;

  useEffect(() => {
    if (id === undefined) {
      navigate('/');
    } else if (dummyNewsData[0] === undefined) {
      navigate('/');
    } else {
      setDataIndex(0);
      setNewsDetail(dummyNewsData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return(
    <div className="container news_detail">
      <div className="news_header">
        <p 
          {...(newsDetail !== undefined && {className : `icon ` + newsDetail[0].category})}
        >{
          newsDetail !== undefined &&
          newsDetail[0].category
        }</p>
        <p className="title">{newsDetail !== undefined && newsDetail[0].title}</p>
        <p className="date">{newsDetail !== undefined && dateToString(newsDetail[0].regdate, 'yyyy.MM.dd')}</p>
        {(newsDetail !== undefined && newsDetail[0]?.attachment !== undefined && newsDetail[0]?.attachment !== null) && (
          <div className="board_detail_attachment">
            <p className="title">{t('news_detail_attachment')}</p>
            <div className="box">
              <div
                className="button_attachment"
                onClick={() => {
                  // dispatch(connectModalSetting(!modalOpen));
                  // window.location.href = `${SERVER_URL}/${newsDetail[0].attachment}`;
                  window.open(`https://www.nextor.ai/${newsDetail[0].attachment}`, '_blank', 'download');
                }}
              >
                <p>
                  {getLastPath(newsDetail[0].attachment)}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="news_body">
        <div 
          className="news_detail_content"
          {...(
            newsDetail !== undefined &&
            { dangerouslySetInnerHTML : {__html: newsDetail[0].content} }
          )}
        ></div>
        <div className="news_footer">
          {dataIndex === 0 && <div className={classNames({empty : dataIndex === 0})} style={{"width" : "107px"}}></div>}
          <Link 
            className={classNames("news_button before", {hide : dataIndex === 0})}
            to={
              // dummyNewsData[0] === undefined
              // ? `/` 
              // : newsData[dataIndex - 1] === undefined 
              // ? (`/news/detail/${newsData[dataIndex]._id}`)
              // : (`/news/detail/${newsData[dataIndex - 1]._id}`)
              `/`
            }
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }}
          >{t('news_detail_back')}</Link>
          <p 
            className="news_button news_main"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
              navigate(`/news/`);
            }}
          >{t('news_detail_all')}</p>
          <Link 
            className={classNames("news_button next", {hide : dataIndex === (dummyNewsData.length - 1)})} 
            to={
              // newsData[dataIndex] === undefined 
              // ? `/` 
              // :newsData[dataIndex + 1] === undefined 
              // ? (`/news/detail/${newsData[dataIndex]._id}`)
              // : (`/news/detail/${newsData[dataIndex + 1]._id}`)
              `/` 
            }
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
            }}
          >{t('news_detail_next')}</Link>
          {dataIndex === (dummyNewsData.length - 1) && <div className={classNames({empty : dataIndex === (dummyNewsData.length - 1)})} style={{"width" : "107px"}}></div>}
        </div>
      </div>
    </div>
  );
}

export default NewsDetail;