import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import ReactPlayer from 'react-player';

import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import { Controller, Scene } from 'react-scrollmagic';

import { TextBox, NewsBox, InfoBox, NormalButton, MainBox } from '../../components';
import { GNBState, RootState, NEWSState, currentTabSetting } from '../../store';

import './nextor.scss';

import dummyNewsData from '../../assets/dummy.json';

type NextorProps = {
  tabRef: any;
}

const Nextor = ({ tabRef }: NextorProps) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gnb:GNBState = useSelector((state:RootState) => state.gnb);
  const news:NEWSState = useSelector((state: RootState) => state.news);

  /**
   * year plan data
   * 연간 계획 내용 업데이트 시 여기에 추가/수정하면 됩니다.
   * @박지우 2022.12.08
   */
  const YearPlanMapData = [
    { year: 2023, data: [t('main_content6_2023_1'), t('main_content6_2023_2'), t('main_content6_2023_3'), t('main_content6_2023_4'), t('main_content6_2023_5')] },
    { year: 2022, data: [t('main_content6_2022_1'), t('main_content6_2022_2'), t('main_content6_2022_3'), t('main_content6_2022_4'), t('main_content6_2022_5')] },
    { year: 2021, data: [t('main_content6_2021_1'), t('main_content6_2021_2'), t('main_content6_2021_3'), t('main_content6_2021_4'), t('main_content6_2021_5')] },
    { year: 2020, data: [t('main_content6_2020_1'), t('main_content6_2020_2'), t('main_content6_2020_3'), t('main_content6_2020_4'), t('main_content6_2020_5'), t('main_content6_2020_6'), t('main_content6_2020_7'), t('main_content6_2020_8')] },
    { year: 2019, data: [t('main_content6_2019_1'), t('main_content6_2019_2')] }
  ];

  /**
   * year plan map
   * @박지우 2022.12.08
   */
  const yearPlanMap = YearPlanMapData.map((data: any, index) => (
    <div className="box" key={index}>
      <p className="year" data-aos="fade-up">{data.year}</p>
      <div className="box_inner" data-aos="fade-up">
        {data.data.map((data: any, index:number) => (
          <p key={index} className='description'>{data}</p>
        ))}
      </div>
    </div>
  ));

  /**
   * Incruit 현재 열린 위치
   * @value 1 - tab1 : 복리후생, 2 - tab2 : 채용절차, 3 - tab3 : 입사지원
   * @박지우 2022.12.09
   */
  const [content8Tab, setContent8Tab] = useState(1);

  /**
   * tabHandler
   * tab1 : 복리후생, 2 - tab2 : 채용절차, 3 - tab3 : 입사지원
   * @박지우 2022.12.09
   */
  const tabHandler = (index: number) => {
    let key = index as number + 1;
    return (
      <p
        key={key}
        className={classNames('title', { on: content8Tab === key })}
        onClick={() => {
          setContent8Tab(key);
        }}
      >
        {t(`main_content9_tab_` + key + `_title`)}
      </p>
    );
  }

  /**
   * content9 - Incruit data
   * @박지우 2022.12.09
   */
  const IncruitMapData = [
    { category: "tab1", title: t('main_content9_tab_1_box1') },
    { category: "tab1", title: t('main_content9_tab_1_box2') },
    { category: "tab1", title: t('main_content9_tab_1_box3') },
    { category: "tab1", title: t('main_content9_tab_1_box4') },
    { category: "tab1", title: t('main_content9_tab_1_box5') },
    { category: "tab2", title: t('main_content9_tab_2_box1_title'), description: t('main_content9_tab_2_box1_description') },
    { category: "tab2", title: t('main_content9_tab_2_box2_title'), description: t('main_content9_tab_2_box2_description') },
    { category: "tab2", title: t('main_content9_tab_2_box3_title'), description: t('main_content9_tab_2_box3_description') },
    { category: "tab3", title: t('main_content9_tab_3_box1'), url: 'https://www.saramin.co.kr/zf_user/company-info/view?csn=TWNOQkNiQjB4eWNXRERIYXQ0VklLZz09' },
    { category: "tab3", title: t('main_content9_tab_3_box2'), url: 'https://www.jobkorea.co.kr/Recruit/Co_Read/C/sensevis1113' },
    { category: "tab3", title: t('main_content9_tab_3_box3'), url: 'support@nextor.ai' }
  ]

  /**
   * content9 - Incruit data.filter
   * @박지우 2022.12.09
   */
  const tab1 = IncruitMapData.filter(e => e.category === 'tab1');
  const tab2 = IncruitMapData.filter(e => e.category === 'tab2');
  const tab3 = IncruitMapData.filter(e => e.category === 'tab3');

  const marqueeHandler = (index: number) => {
    let key = index as number + 1;
    return (
      <div key={key} className='img'></div>
    );
  }

  const ballHandler = (index: number) => {
    let key = index as number + 1;
    return (
      <div key={key} className="ball"></div>
    );
  }

  useEffect(() => {
    tabRef.current[gnb.index].scrollIntoView({behavior: 'smooth'});
  }, [gnb]);

  return (
    <div className="container nextor">
      <div className="content1to3" ref={el => (tabRef.current[0] = el)}>
        <Controller globalSceneOptions={{ triggerHook: 'onLeave' }} >
          {/* content1 */}
          <div className='move_gnb_1'></div>
          <Scene pin>
            <div className="content1">
              <MouseParallaxContainer globalFactorX={0.1} globalFactorY={0.1}>
                <MouseParallaxChild factorX={-0.7} factorY={-0.8} className="center">
                  {[...Array(4)].map((e, index) => ballHandler(index))}
                </MouseParallaxChild>
                <MouseParallaxChild factorX={-0.3} factorY={-0.5} className="yellow_ball" />
                <MouseParallaxChild factorX={-0.2} factorY={-0.2} className="window" />
                <MouseParallaxChild factorX={-0.8} factorY={-0.7} className="purple_ball" />
                <MouseParallaxChild factorX={-0.5} factorY={-0.6} className="blue_c" />
                <MouseParallaxChild factorX={-0.3} factorY={-0.1} className="pink_c" />
                <MouseParallaxChild factorX={-0.6} factorY={-0.2} className="hands" />
                <MouseParallaxChild factorX={-0.9} factorY={-0.6} className="cross1" />
                <MouseParallaxChild factorX={-0.2} factorY={-0.8} className="cross2" />
                <MouseParallaxChild factorX={-0.7} factorY={-0.4} className="cross3" />
                <MouseParallaxChild factorX={-0.6} factorY={-0.2} className="cross4" />
                <MouseParallaxChild factorX={-0.1} factorY={-0.4} className="cross5" />
              </MouseParallaxContainer>
              <TextBox title={t('main_content1_title')} description={t('main_content1_description')} />
            </div>
          </Scene>
          {/* content2 */}
          <Scene pin>
            <div className="content2">
              <video muted autoPlay loop playsInline>
                <source src={require('../../assets/images/content2_bg.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <TextBox title={t('main_content2_title')} description={t('main_content2_description')} />
            </div>
          </Scene >
          <div className='move_gnb_2'></div>
          {/* content3 */}
          <Scene pin>
            <div className="content3" 
              // ref={el => (tabRef.current[1] = el)}
            >
              <video muted autoPlay loop playsInline>
                <source src={require('../../assets/images/content3_bg.mp4')} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <TextBox title={t('main_content3_title')} description={t('main_content3_description')} />
            </div>
          </Scene>
        </Controller>
      </div>

      {/* content4 */}
      <div className="content4" ref={el => (tabRef.current[1] = el)}>
        <div className="content_inner">
          <p 
            className="title" 
            data-aos="fade-up"
          >{t('main_content4_title')}</p>
          <p 
            className="description"
            data-aos="fade-up"
          >{t('main_content4_descrption')}</p>

          <ReactPlayer
            url={'https://youtu.be/UbJUrAIBbzc'}
            controls={true}
            width={"100%"}
            height={"100%"}
            data-aos="fade-up"
          />

          <div className="content4_box">
            <MainBox title={t('main_content4_box1_title')} description={t('main_content4_box1_description')} />
            <MainBox title={t('main_content4_box2_title')} description={t('main_content4_box2_description')} />
            <MainBox title={t('main_content4_box3_title')} description={t('main_content4_box3_description')} />
            <MainBox title={t('main_content4_box4_title')} description={t('main_content4_box4_description')} />
          </div>

          <NormalButton title={t('header1')} out onClick={() => {
            window.open('https://zemiverse.com', '_blank');
          }} />
        </div>
      </div>
      {/* content5 */}
      <div className="content5" ref={el => (tabRef.current[2] = el)}>
        <div className="content_inner">
          <p className="title" data-aos="fade-up">{t('main_content5_title')}</p>
          <p className="description" data-aos="fade-up">
            {t('main_content5_description')}
            <span>{t('main_content5_description_span')}</span>
          </p>
          <div className="content5_box">
            <MainBox title={t('main_content5_box1_title')} description={t('main_content5_box1_description')} />
            <MainBox title={t('main_content5_box2_title')} description={t('main_content5_box2_description')} />
            <MainBox title={t('main_content5_box3_title')} description={t('main_content5_box3_description')} />
            <MainBox title={t('main_content5_box4_title')} description={t('main_content5_box4_description')} />
          </div>
        </div>
      </div>
      {/* content6 */}
      <div className="content6 move_gnb_04" ref={el => (tabRef.current[3] = el)}>
        <div className="content_inner">
          <p className="title" data-aos="fade-up">{t('main_content6_title')}</p>
          <div className="content6_box1">
            <MainBox title={t('main_content6_box1_title')} description={t('main_content6_box1_desctiption')} image />
            <MainBox title={t('main_content6_box2_title')} description={t('main_content6_box2_desctiption')} image />
            <MainBox title={t('main_content6_box3_title')} description={t('main_content6_box3_desctiption')} image />
            <MainBox title={t('main_content6_box4_title')} description={t('main_content6_box4_desctiption')} image />
          </div>
          <div className="content6_box2">
            {yearPlanMap}
          </div>
        </div>

      </div>
      {/* content7 */}
      <div className="content7">
        <p className="title" data-aos="fade-up">{t('main_content7_title')}</p>
        <div className="marquee_box" >
          <div className="marquee">
            {[...Array(7)].map((e, index) => marqueeHandler(index))}
          </div>
          <div className="marquee">
            {[...Array(7)].map((e, index) => marqueeHandler(index))}
          </div>
        </div>
      </div>
      {/* content8 */}
      <div className="content8 move_gnb_03" ref={el => (tabRef.current[4] = el)}>
        <div className="content_inner">
          <p className="title" data-aos="fade-up">{t('main_content8_title')}</p>
          <div className="content8_box">
            {dummyNewsData.length <= 0 ? (
              <div className='no_list'>
                <p>No List</p>
              </div>
            ) : (
              dummyNewsData.slice(0, 4).map((data: any, index) => (
                <NewsBox key={index} data={data} />
              ))
            )}
          </div>
          <NormalButton title={t('main_content8_button_more')} onClick={() => {
            dispatch(currentTabSetting(0));
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            navigate('/news');
          }} />
        </div>
      </div>
      {/* content9 */}
      <div className="content9 move_gnb_05" ref={el => (tabRef.current[5] = el)}>
        <div className="content_inner">
          <p className="title" data-aos="fade-up">{t('main_content9_title')}</p>
          <p className="description" data-aos="fade-up">{t('main_content9_description')}</p>
          <div className="tab_menu">
            {[...Array(3)].map((e, index) => tabHandler(index))}
          </div>
          <div className={classNames(`tabbox`, `index` + content8Tab)}>
            <p className={classNames(`description`, `index` + (content8Tab))}>{t(`main_content9_tab_` + (content8Tab) + `_description`)}</p>
            <div className={classNames(`box_inner`, `index` + (content8Tab))}>
              {content8Tab === 1 ? (
                tab1.map((data, index) => (
                  <MainBox key={index} title={data.title} description={''} image />
                ))
              ) : content8Tab === 2 ? (
                tab2.map((data: any, index) => (
                  <>
                    <MainBox key={index} title={data.title} description={data.description} image />
                    <div className="arrow_right"></div>
                  </>
                ))
              ) : (
                tab3.map((data, index) => {
                  if (index === 2) {
                    return(
                      <MainBox title={data.title} description={''} image email='support@nextor.ai'/>
                    )
                  } else {
                    return(
                      <MainBox title={data.title} description={''} image onClick={() => {
                        window.open(data.url, '_blank');
                      }}/>
                    )
                  }
                })
              )}
            </div>
          </div>
        </div>
      </div>
      {/* content10 */}
      <div className="content10">
        <p className="title" data-aos="fade-up">{t('main_content10_title')}</p>
        <iframe title="google_maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3165.4372767325244!2d127.03547847734403!3d37.49760357205649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357ca1556e116f95%3A0xf45d5f6885a06dcc!2z7ISc7Jq47Yq567OE7IucIOqwleuCqOq1rCDrhbztmITroZwgNDE5!5e0!3m2!1sko!2skr!4v1689123794634!5m2!1sko!2skr" data-aos="fade-up"/>
        <div className="box">
          <InfoBox title={t('main_content10_box1_title')} description={t('main_content10_box1_description')} />
          <div className="box_inner">
            <InfoBox title={t('main_content10_box2_title')} description={t('main_content10_box2_description')} email/>
            <InfoBox title={t('main_content10_box3_title')} description={t('main_content10_box3_description')} />
            <InfoBox title={t('main_content10_box4_title')} description={t('main_content10_box4_description')} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nextor;