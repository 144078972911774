import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';

import { NewsItem, MetaTag } from '../../components';
// import { NewsBoxProps } from '../../components/Main/NewsBox/NewsBox';
import { RootState, NEWSState } from '../../store';

import './news.scss';

import dummyNewsData from '../../assets/dummy.json';

const News = () => {

  const { t }:{t:any} = useTranslation();
  // const news:NEWSState = useSelector((state: RootState) => state.news);

  const [currentPage, setCurrentPage] = useState(0); // 현재 페이지
  const [currentItems, setCurrentItems] = useState<Array<any>>([]); // 현재 페이지 아이템
  const [currentOriginData, setCurrentOriginData] = useState<Array<any>>(dummyNewsData); // 현재 페이지 모든 데이터
  const [pageCount, setPageCount] = useState(Math.ceil(dummyNewsData.length / 5)); //현재 페이지 모든 데이터에 맞춰서 페이지 숫자
  const [nowTab, setNowTab] = useState(0); //현재 탭

  // const nowTab = useSelector((state: RootState) => state.news.mainTab);

  /**
   * 현재 페이지의 탭
   */
  const TabData:any[] = [
    { title: t('news_sort_total') },
    { title: t('news_sort_news') },
    { title: t('news_sort_notice') }
  ];

  /**
   * 페이지가 변경됬을 때 현재 페이지를 변경
   * 
   * @param event 현재 페이지
   */
  const handlePageClick = (event : any) => {
    setCurrentPage(event.selected);
  };


  /**
   * 페이지나 페이지내 아이템의 변경이 일어났을 때 현재 페이지의 데이터를 다시 불러옴
   */
  const pageItemUpdate = async() => {
    // console.log("currentPage", currentPage);
    let data = currentOriginData.slice(currentPage * 5, (currentPage * 5) + 5);
    await setCurrentItems(data);
  }

  /**
   * 탭을 클릭했을 때 데이터를 세팅 하는 부분
   * 
   * @param index 입력 받은 탭 number(전체: 0, News: 1, Notice: 2)
   */
  const setTabData = (index: number) => {
    // dispatch(newsMainTabSetting(index));
    setNowTab(index);
    setCurrentPage(0);
  }

  /**
   * 탭 랜더링 부분
   */
  const tabMap = TabData.map((data: any , index : number) => (
    <p 
      className={classNames({on : nowTab === index})}
      key={index}
      onClick={() => {
        setTabData(index);   
      }}
    >
      {data.title}
    </p>
  ));

  /**
   * tab에 맞춰서 데이터를 저장 함
   */
  useEffect(()=> {
    if(nowTab === 0) {
      setCurrentOriginData(dummyNewsData);
    } else {
      let data = dummyNewsData.filter((e : any) => e.category === TabData[nowTab].title);
      setCurrentOriginData(data);
    }
  }, [nowTab, dummyNewsData]);

  /**
   * 페이지가 변경 되었는지 감지
   * 페이지 내의 데이터가 변경 되었는지 감지
   * 
   * setPageCount() 데이터 변경이 일어났을 때, 전체 페이지 수를 다시 셈
   * pageItemUpdate() 현재 페이지가 변경됬을 때, 현재 페이지의 데이터를 다시 불러 옴
   */
  useEffect(() => {
    setPageCount(Math.ceil(currentOriginData.length / 5));
    pageItemUpdate();
  },[currentPage, currentOriginData]);

  return(
    <div className="container news">
      <MetaTag metaTagNameTitle={t(`meta_tag_news_title`)} metaDescription={t(`meta_tag_news_description`)} url="https://exais.io/ecosystem" />
      <p className="news_title">{t('news_title')}</p>
      <div className="content_frame">
        <div className="news_menu">
          {tabMap}
        </div>
        {currentItems.length <= 0 ? (
          <div className="no_data">
            <p>No List</p>
          </div>
        ) : (
          <NewsItem 
            {...({currentItems: currentItems})}
          />
        )}
        {currentItems.length <= 0 ? (
          null
        ) : (
          <ReactPaginate
          className="news_pagination"
          breakLabel="..."
          previousLabel={null}
          nextLabel={null}
          pageCount={pageCount}
          onPageChange={(e:any) => handlePageClick(e)}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          forcePage={currentPage}
          />
        )}
        
      </div>
    </div>
  );
}

export default News;